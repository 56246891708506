import React from "react";
import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const Metronome = (props: SvgIconProps) => (
  <SvgIcon
    fillRule="evenodd"
    clipRule="evenodd"
    color="secondary"
    fontSize="small"
    {...props}
  >
    <path
      d="M12.1698 22.9091L5.66038 16.404C6.22641 15.2727 5.9434 13.8586 5.09434 13.0101C3.96226 11.8788 1.98113 11.8788 0.849057 13.0101C-0.283019 14.1414 -0.283019 16.1212 0.849057 17.2525C1.69811 18.101 3.11321 18.3838 4.24528 17.8182L10.7547 24.3232L12.1698 22.9091Z"
      fill="#231F20"
    />
    <path
      d="M29.151 13.0101C28.0189 11.8788 26.0377 11.8788 24.9057 13.0101C24.0566 13.8586 23.7736 15.2727 24.3396 16.404L17.8302 22.9091L19.2453 24.3232L25.7547 17.8182C26.8868 18.3838 28.3019 18.101 29.151 17.2525C30.283 15.8384 30.283 14.1414 29.151 13.0101Z"
      fill="#231F20"
    />
    <path
      d="M22.6415 23.7576L22.9245 25.1717V26.0202H17.8302C17.5472 24.8889 16.4151 24.0404 15 24.0404C13.5849 24.0404 12.4528 24.8889 12.1698 26.0202H7.07548V25.1717L7.3585 23.7576L5.66038 22.0606L5.09435 24.8889V28H25.1887V25.1717L24.3396 22.0606L22.6415 23.7576Z"
      fill="#231F20"
    />
    <path
      d="M9.0566 16.9697L12.7358 1.9798H17.2641L20.9434 16.9697L22.6415 15.2727L18.9623 0H11.3208L7.35849 15.2727L9.0566 16.9697Z"
      fill="#231F20"
    />
  </SvgIcon>
);

export default Metronome;
